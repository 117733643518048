import React from "react";
import Genesis from "../media/genesis.png"
import Omega from "../media/omega.png"
import Galactic from "../media/galactic.png"
import NFTworldLogo from "../media/nftworldLogo.png";
import NFTworld from "../media/nftworld.png";
import SandboxLogo from "../media/sandboxLogo.png";
import Sandbox from "../media/sandbox.png";

const Roadmap = () => (
    <div id="roadmap" className="flex w-full justify-center items-center 2xl:px-20 gradient-bg-transactions">
        <div className="flex flex-col md:p-12 py-12 px-4">
            <h3 className="text-white text-4xl font-bold text-center my-2">
                Our Roadmap
            </h3>
            <div className="flex flex-wrap space-x-8 justify-center items-center mt-4">
                <div className="m-4 flex flex-1
                    2xl:min-w-[350px]
                    2xl:max-w-[400px]
                    sm:min-w-[270px]
                    sm:max-w-[300px]
                    min-w-full
                    flex-col p-3"
                >
                    <div className="flex flex-col items-center w-full my-3">
                        <img src={Genesis} alt="logo" />
                    </div>
                </div>
                <div className="m-4 flex flex-1
                    2xl:min-w-[350px]
                    2xl:max-w-[400px]
                    sm:min-w-[270px]
                    sm:max-w-[300px]
                    min-w-full
                    flex-col p-3"
                >
                    <div className="flex flex-col items-center w-full my-3">
                        <img src={Omega} alt="logo" />
                    </div>
                </div>
                <div className="m-4 flex flex-1
                    2xl:min-w-[350px]
                    2xl:max-w-[400px]
                    sm:min-w-[270px]
                    sm:max-w-[300px]
                    min-w-full
                    flex-col p-3"
                >
                    <div className="flex flex-col items-center w-full my-3">
                        <img src={Galactic} alt="logo" />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap space-x-8 justify-center items-center mt-4">
                <div className="m-4 flex flex-1
                    2xl:min-w-[350px]
                    2xl:max-w-[400px]
                    sm:min-w-[270px]
                    sm:max-w-[300px]
                    min-w-full
                    flex-col p-3"
                >
                    <div className="flex flex-col items-center w-full my-3">
                        <a href={"https://opensea.io/assets/0xbd4455da5929d5639ee098abfaa3241e9ae111af/540"} target="_blank" rel="noreferrer">
                            <img src={NFTworld} className="border-4 border-white" alt="nftworld" />
                            <img src={NFTworldLogo} className="mt-2" alt="nftworldLogo" />
                        </a>
                    </div>
                </div>
                <div className="m-4 flex flex-1
                    2xl:min-w-[350px]
                    2xl:max-w-[400px]
                    sm:min-w-[270px]
                    sm:max-w-[300px]
                    min-w-full
                    flex-col p-3"
                >
                    <div className="flex flex-col items-center w-full my-3">
                        <a href={"https://opensea.io/assets/0x50f5474724e0ee42d9a4e711ccfb275809fd6d4a/130950"} target="_blank" rel="noreferrer">
                            <img src={Sandbox} className="border-4 border-white" alt="sandbox" />
                            <img src={SandboxLogo} className="mt-2" alt="sandboxLogo" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Roadmap;

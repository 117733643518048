import React from "react";
import AlienCycle from "../media/alienCycle.gif";

interface CardData {
  title: string;
  subtitle: string;
}

const Card = (titles: CardData) => (
    <div className="flex flex-row justify-start items-start md:w-8/12 white-glassmorphism p-6 m-4 shadow-xl">
      <div className="ml-5 flex flex-col flex-1">
        <h3 className="mt-2 text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-green-400 to-green-200">{titles.title}</h3>
        <p className="mt-3 mb-3 text-lg text-white md:w-11/12">
          {titles.subtitle}
        </p>
      </div>
    </div>
  );

  const About = () => (
    <div id="about" className="flex w-full justify-center items-center gradient-bg-roadmap">
      <div className="flex mf:flex-row flex-col items-center justify-between md:p-20 py-12 px-4">
          <div className="flex-1 flex flex-col justify-start items-center">
              <Card
              title="Who are we?"
              subtitle="Alien Scum Fleet is a collection of alien scum working to rebuild their fleet in the Ethereum metaverse. After a terminal battle with the evil Goop Galandor and his massive army of Goop Soldiers, the defeated Alien Scum fled their home planet of Gargaldeez and crash landed on a slab of rock, indefinitely floating through space. Using the power of blockchain and the mystical potency of $SLATT, they need our help to restore the lost glory of Gargaldeez."
              />
          </div>
          <div className="flex-1 flex flex-col justify-start items-center">
              <div className="flex flex-col flex-1 items-center justify-start w-full mf:mt-0 mt-10">
                <img src={AlienCycle} className="w-96 border-4 border-white" />
              </div>
          </div>
      </div>
    </div>
  );


export default About;

import Navbar from './components/Navbar';
import Welcome from './components/Welcome';
import Roadmap from './components/Roadmap';
import About from './components/About';
import TeamInfo from './components/TeamInfo';
import Footer from './components/Footer';

const App = () => (
  <div className="min-h-screen">
    <div
      className="w-full h-full bg-no-repeat bg-cover bg-left bg-fixed bg-starscape">
      <Navbar />
      <Welcome />
    </div>
    <About />
    <div
      className="w-full h-full bg-no-repeat bg-cover bg-left bg-fixed bg-starscape">
      <Roadmap />
      <TeamInfo />
    </div>
    <Footer />
  </div>
);

export default App;

import React from "react";
import Lawi from "../media/lawi.png";
import Robatic from "../media/robatic.png";
import Lashes from "../media/lashes.png";
import Peepeebunzo from "../media/peepeebunzo.png";

interface TeamMember {
  name: string;
  description: string;
  picture: any;
  twitter: any;
}

const TeamCard = (member: TeamMember) => {

    return (
    <div className="m-4 flex flex-1
      2xl:min-w-[350px]
      2xl:max-w-[400px]
      sm:min-w-[270px]
      sm:max-w-[300px]
      min-w-full
      flex-col p-3"
    >
      <div className="flex flex-col items-center w-full my-3">
        <a href={member.twitter} target="_blank" rel="noreferrer">
          <img src={member.picture} alt="pfp" className="w-32 border-2 border-white" />
        </a>
        <br />
        <p className="text-white text-base"> {member.name}</p>
        <p className="text-white text-base mt-1"> {member.description}</p>
      </div>
    </div>
  );
};

const TeamInfo = () => {

  return (
    <div className="flex w-full justify-center items-center 2xl:px-20 gradient-bg-transactions">
      <div className="flex flex-col md:p-12 py-12 px-4">
        <h3 className="text-white text-4xl font-bold text-center my-2">
            Meet the Fleet
        </h3>
        <div className="flex flex-wrap justify-center items-center mt-4">
          <a></a>
            <TeamCard name="Lawi" description="Co-founder" twitter="https://mobile.twitter.com/CryptoLawi" picture={Lawi} />
            <TeamCard name="Robatic" description="Co-founder" twitter="https://twitter.com/Robaticman98" picture={Robatic}/>
            <TeamCard name="Lashes" description="Project Manager" twitter="https://twitter.com/0xLashes" picture={Lashes}/>
            <TeamCard name="Peepeebunzo" description="Finance" twitter="https://twitter.com/peepeebunzo" picture={Peepeebunzo}/>
       </div>
      </div>
    </div>
  );
};

export default TeamInfo;

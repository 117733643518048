import React  from "react";
import logo from "../media/logo.png";
import twitter from "../media/twitter.png";
import discord from "../media/discord.png";
import medium from "../media/medium.png";
import calendar from "../media/calendar.png";

const Navbar = () => {
  return (
    <nav className="font-sans flex flex-col text-center content-center sm:flex-row sm:text-left sm:justify-between py-2 px-6 shadow sm:items-baseline w-full">
      <div className="mb-2 sm:mb-0 space-x-2 flex flex-row">
        <img className="h-16 w-16 self-center mt-4" src={logo} />
        <a href="https://twitter.com/alienscumfleet" target="_blank" rel="noreferrer">
          <img className="h-10 w-10 mt-7" src={twitter} />
        </a>
        <a href="https://discord.gg/p3bcDT7zEU" target="_blank" rel="noreferrer">
          <img className="h-10 w-10 mt-7" src={discord} />
        </a>
        <a href="https://medium.com/@gargaldeez" target="_blank" rel="noreferrer">
          <img className="h-10 w-10 mt-7" src={medium} />
        </a>
        <a href="https://nftcalendar.io/event/alien-scum-fleet/" target="_blank" rel="noreferrer">
          <img className="h-10 w-10 mt-7" src={calendar} />
        </a>
      </div>
      <div className="sm:mb-0 self-center">
        <a href="#about" className="text-lg font-semibold no-underline text-white ml-2 px-3">About</a>
        <a href="#roadmap" className="text-lg font-semibold no-underline text-white ml-2 px-3">Roadmap</a>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";
import Mint from "./Mint";
import Counter from "./Counter";
import WelcomeBanner from "../media/welcomeBanner.png";

const Welcome = () => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-col flex-col items-start justify-between">
        <div className="flex flex-col flex-1 space-y-3 items-center justify-center w-full">
          <img src={WelcomeBanner} className="2xl:min-w-[450px]
                    2xl:max-w-[500px]
                    sm:min-w-[200px]
                    sm:max-w-[350px]" alt="asteroid" />
          <p className="text-white text-base">
            Genesis Collection Sold Out!
          </p>
          <p className="text-white text-base">
            Omega Collection Coming Soon - .0169 ETH Mint
          </p>
        </div>
        <div className="flex flex-col avatar scale-75 mt-6 mx-100 flex-1 items-center justify-top w-full">
        </div>
      </div>
    </div>
  );
};

export default Welcome;

import React from "react";
import logo from "../media/logo.png";
import nftcalendar from "../media/nftcalendar.png";
import twitter from "../media/twitter.png";
import discord from "../media/discord.png";
import medium from "../media/medium.png";

const Footer = () => (
  <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
    <div className="w-full flex sm:flex-row flex-col justify-between items-center">
      <div className="flex flex-[0.5] justify-center items-center space-x-4">
        <img src={logo} alt="logo" className="w-12" />
        <a href="https://twitter.com/alienscumfleet" target="_blank" rel="noreferrer">
          <img className="h-12 w-12" src={twitter} />
        </a>
        <a href="https://discord.gg/p3bcDT7zEU" target="_blank" rel="noreferrer">
          <img className="h-12 w-12" src={discord} />
        </a>
        <a href="https://medium.com/@gargaldeez" target="_blank" rel="noreferrer">
          <img className="h-12 w-12" src={medium} />
        </a>
      </div>
      <div className="flex flex-1 justify-center sm:mt-0 mt-5 w-full">
        <p className="text-white text-base mx-2">As seen on</p>
        <a href="https://nftcalendar.io/" target="_blank" rel="noreferrer">
          <img className="h-20 w-20" src={nftcalendar} />
        </a>
      </div>
    </div>

    <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5 " />

    <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
      <p className="text-white text-left text-xs">@AlienScumFleet</p>
      <p className="text-white text-right text-xs">All rights reserved</p>
    </div>
  </div>
);

export default Footer;
